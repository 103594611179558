import { makeStyles } from '@material-ui/styles';
import * as Colors from '../../themes/colors'

export default makeStyles((theme) => ({
  subscription_card_container: {
    width: 150,
    minHeight: 135,
    backgroundColor: '#e7e6e6',
    borderRadius: 30,
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  subscription_cardItem_container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  card_title: {
    color: Colors.BLACK,
    fontFamily: 'Heebo, sans-serif',
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: 1,
    marginBottom: 5,
  },
  plan_action_container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  plan_price: {
    fontFamily: 'Heebo, sans-serif',
    fontSize: 12,
    marginBottom: 7,
    marginTop: 0,
    letterSpacing: '.1rem',
    fontWeight: 400,
  },
  cancelButton: {
    backgroundColor: Colors.RED,
  },
  updateInfoBtn: {
    height: 'auto',
    width: 'auto',
    backgroundColor: '#707070',
    border: '1px solid #000',
    padding: 15,
    fontSize: 15,
  },
}));
