import React from 'react';
import useStyles from './styles';
import clsx from 'clsx';

function CommonButton(props) {
  const classes = useStyles();
  return (
    <button
      className={clsx(classes.button_container, props.className)}
      onClick={props.onClick}
      style={props.style && props.style}
    >
      {props.text ? props.text : 'Subscribe'}
    </button>
  );
}
export default CommonButton;
