import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Theme from '../src/themes';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@material-ui/styles';
import setAuthToken from './utils/setAuthToken';
import axios from 'axios';
import config from './config';

axios.defaults.baseURL = config.BASE_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';
const token = localStorage.getItem('token');
if (token) {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}

axios.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      setAuthToken(false);
      window.location.replace('/login');
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={Theme.default}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
