const WHITE = '#ffffff';
const BLACK = '#000000';
const LIGHT_BLACK = '#747474';
const GRAY = '#8F8F8F';
const BACKGROUND_BLACK = '#212121';
const RED = '#ff0000';
const LIGHT_BLUE = '#1976d2';
const GREEN = '#008000';
const PINK = '#EB3E8C';

export {
  WHITE,
  BLACK,
  LIGHT_BLACK,
  GRAY,
  BACKGROUND_BLACK,
  RED,
  LIGHT_BLUE,
  GREEN,
  PINK,
};
