import axios from 'axios';
import { logout } from './appUtils';
import setAuthToken from './setAuthToken';

export const login = (data, navigate, onError, setLoading) => {
  axios
    .post(`auth/userLogin`, data)
    .then((res) => {
      const { token } = res.data.data;
      setAuthToken(token);
      localStorage.setItem('jwtToken', token);
      setLoading(false);
      navigate('/subscription');
    })
    .catch((err) => {
      setLoading(false);
      onError(err.response);
    });
};

export const getSubscriptionDetail = (
  onResponse,
  onError,
  setSubscriptionLoading
) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  axios
    .get(`/subscription/getSubscriptionDetail`, config)
    .then((res) => {
      onResponse(res);
      setSubscriptionLoading(false);
    })
    .catch((err) => {
      setSubscriptionLoading(false);
      onError(err.response);
    });
};

export const planDetails = (onResponse, onError, setPlanLoading) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  axios
    .get(`subscription/planDetails`, config)
    .then((res) => {
      onResponse(res);
      setPlanLoading(false);
    })
    .catch((err) => {
      onError(err.response);
      setPlanLoading(false);
    });
};

export const createSession = (data, onSuccessCreateSession, onError) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  axios
    .post(`subscription/createSession`, data, config)
    .then((res) => {
      onSuccessCreateSession(res);
    })
    .catch((err) => {
      onError(err.response);
    });
};

export const subscriptionCancel = (
  sID,
  onSuccessCancelSubscription,
  onError,
  setCancelSubscriptionLoading
) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  axios
    .post(`subscription/cancel?id=${sID}`, {}, config)
    .then((res) => {
      setCancelSubscriptionLoading(false);
      onSuccessCancelSubscription(res);
    })
    .catch((err) => {
      setCancelSubscriptionLoading(false);
      onError(err.response);
    });
};

export const forgotPassword = (
  data,
  onSuccess,
  onError,
  setLoading
) => {
  axios
    .post(`auth/forgotPassword`, data)
    .then((res) => {
      setLoading(false);
      onSuccess(res.data);
    })
    .catch((err) => {
      setLoading(false);
      onError(err.response);
    });
};

export const updatePaymentInfo = (onSuccess, onError) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  axios
    .post(`subscription/updatePaymentInfo`, {}, config)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err.response);
    });
};