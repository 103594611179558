import React, { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Container,
  Typography,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../components/Iconify';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';
import { getErrorMessage } from '../../utils/appUtils';
import { useSnackbar } from 'notistack';
import { getUserProfile, login } from '../../utils/api';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import * as Colors from '../../themes/colors';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const EmailTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: Colors.LIGHT_BLACK,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: Colors.LIGHT_BLACK,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: Colors.LIGHT_BLACK,
    },
    '&:hover fieldset': {
      borderColor: Colors.LIGHT_BLACK,
    },
    '&.Mui-focused fieldset': {
      borderColor: Colors.LIGHT_BLACK,
    },
  },
});

const LoginButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  padding: '6px 12px',
  lineHeight: 1.5,
  backgroundImage: 'linear-gradient(to right, #D2303C , #7D00C9)',
  marginTop: '15px',
  color: Colors.WHITE,
  height: '50px',
});

export default function Login() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,

    onSubmit: (values) => {
      setLoading(true);
      login(
        {
          email: values.email,
          password: values.password,
        },
        navigate,
        (err) => onError(err),
        setLoading
      );
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <Container
      maxWidth='false'
      style={{ backgroundColor: Colors.BACKGROUND_BLACK }}
    >
      <ContentStyle>
        <Stack sx={{ mb: 5, alignItems: 'center' }}>
          <img
            src={logo}
            height={'150px'}
            width={'150px'}
            alt={'Physique Lab'}
          />
          <Typography
            variant='h4'
            gutterBottom
            color={Colors.GRAY}
            style={{ textAlign: 'center' }}
          >
            Sign in to the
          </Typography>
          <Typography
            variant='h4'
            gutterBottom
            color={Colors.GRAY}
            style={{ textAlign: 'center', marginTop: -20 }}
          >
            Physique Lab
          </Typography>
          <Typography sx={{ color: Colors.GRAY, textAlign: 'center' }}>
            Enter your details below.
          </Typography>
        </Stack>
        <FormikProvider value={formik}>
          <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <EmailTextField
                fullWidth
                autoComplete='username'
                type='email'
                label='Email address'
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                sx={{
                  input: { color: Colors.WHITE },
                  label: { color: Colors.LIGHT_BLACK },
                }}
              />
              <EmailTextField
                fullWidth
                autoComplete='current-password'
                type={showPassword ? 'text' : 'password'}
                label='Password'
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={handleShowPassword} edge='end'>
                        <Iconify
                          sx={{ color: Colors.LIGHT_BLACK }}
                          icon={
                            showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                sx={{
                  input: { color: Colors.WHITE },
                  label: { color: Colors.LIGHT_BLACK },
                }}
              />
            </Stack>

            <LoginButton
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              loading={loading}
            >
              Login
            </LoginButton>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={() => navigate('/forgotPassword')}
                style={{ textTransform: 'none' }}
              >
                <Typography
                  variant='subtitle1'
                  gutterBottom
                  color={Colors.PINK}
                  style={{
                    marginTop: 5,
                    marginBottom: 10,
                  }}
                >
                  Forgot Password
                </Typography>
              </Button>
            </div>
          </Form>
        </FormikProvider>
      </ContentStyle>
    </Container>
  );
}
