import { makeStyles } from '@material-ui/styles';
import * as Colors from '../../themes/colors'

export default makeStyles((theme) => ({
  button_container: {
    backgroundColor: Colors.GREEN,
    width: '70%',
    height: 40,
    border: 'none',
    cursor: 'pointer',
    color: 'white',
    fontFamily: 'Heebo, sans-serif',
    fontWeight: 'bolder',
    fontSize: 13,
    borderRadius: 5,
    letterSpacing: '0.1rem',
    '&:hover': {
      boxShadow: '#000000 0px 1px 3px',
    },
  },
}));
