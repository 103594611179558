import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import Login from './pages/Login';
import Subscription from './pages/Subscription';
import jwtDecode from 'jwt-decode';
import { logout } from './utils/appUtils';
import NotFound from './pages/NotFound';
import ForgotPassword from './pages/ForgotPassword';

if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;
  const decoded = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    logout();
  }
}

export default function Router() {
  return useRoutes([
    localStorage.jwtToken
      ? {
          path: '/',
          children: [
            { path: '/', element: <Navigate to='/subscription' /> },
            { path: '/subscription', element: <Subscription /> },
          ],
        }
      : {
          path: '/',
          children: [
            { path: '/', element: <Navigate to='/login' /> },
            { path: 'login', element: <Login /> },
            { path: 'forgotPassword', element: <ForgotPassword /> },
          ],
        },
    // {
    //   path: '/',
    //   children: [
    //     { path: '404', element: <NotFound /> },
    //     { path: '*', element: <Navigate to='/404' /> },
    //   ],
    // },
    { path: '*', element: <Navigate to='/' replace /> },
  ]);
}
