import setAuthToken from './setAuthToken';

export const getErrorMessage = (error) =>
  error && error.data && error.data.message;

export const logout = () => {
  localStorage.clear();
  setAuthToken(false);
  window.location.href = '/login';
};
