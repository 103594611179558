import { makeStyles } from '@material-ui/styles';
import * as Colors from '../../themes/colors';

export default makeStyles((theme) => ({
  notfound_container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: Colors.BACKGROUND_BLACK,
  },
  notfound_text_container: {
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '90%',
    },
  },
  sorry_label: {
    fontSize: '1.8rem',
    fontWeight: 700,
    fontFamily: 'sans-serif',
    color: Colors.LIGHT_BLUE,
  },
  notfound_note: {
    color: 'rgb(99, 115, 129)',
    fontSize: '1rem',
    lineHeight: 1.5,
    fontFamily: 'sans-serif',
  },
  for0for_text: {
    fontFamily: 'sans-serif',
    color: Colors.LIGHT_BLUE,
    fontSize: '7rem',
    fontWeight: 700,
    margin: '30px 0px',
  },
  goto_button: {
    backgroundColor: Colors.RED,
    borderRadius: 10,
    maxWidth: 200,
    fontFamily: 'sans-serif',
    fontSize: 15,
    fontWeight: 700,
  },
}));
