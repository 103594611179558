import { makeStyles } from '@material-ui/styles';
import * as Colors from '../../themes/colors';

export default makeStyles((theme) => ({
  containerStyle: {
    backgroundColor: Colors.BACKGROUND_BLACK,
  },
  lifetimeCard: {
    color: Colors.WHITE,
    padding: 50,
    boxShadow: '#e3e8ed 0px 1px 3px',
    backgroundColor: Colors.BACKGROUND_BLACK,
    borderRadius: 20,
    textAlign: 'center',
    fontSize: 20,
    fontFamily: 'Heebo, sans-serif',
    fontWeight: 600,
  },
  loaderContainer: {
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
  },
  subscription_container: {
    width: '100vw',
    height: '100vh',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  plan_message: {
    fontSize: 20,
    fontWeight: 400,
    color: Colors.WHITE,
    fontFamily: 'Heebo, sans-serif',
    // textAlign: 'center',
    padding: 0,
    margin: 0,
    marginBottom: 40,
  },
  plan_container: {
    zIndex: 1,
    overflow: 'auto',
    padding: 20,
    margin: 'auto',
    borderRadius: 20,
    paddingTop: 30,
    maxWidth: 500,
    minWidth: 450,
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
    },
  },
  card_container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    // marginTop: 50,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  activeCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // marginTop: 50,
    padding: 50,
    boxShadow: '#e3e8ed 0px 8px 12px',
    backgroundColor: Colors.BACKGROUND_BLACK,
    borderRadius: 20,
    zIndex: 0,
  },
  cancelNote: {
    color: Colors.WHITE,
    textAlign: 'center',
    fontSize: 20,
    fontFamily: 'Heebo, sans-serif',
    fontWeight: 600,
  },
  headerContainer: {
    width: '100%',
    backgroundColor: Colors.BACKGROUND_BLACK,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    padding: '15px 0px',
  },
  userName: {
    margin: 0,
    padding: 0,
    lineHeight: 1,
    fontFamily: 'Heebo, sans-serif',
    color: Colors.WHITE,
    fontWeight: 600,
    fontSize: 18,
  },
  headerActionContainer: {
    marginRight: 15,
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
  },
  headerMenu: {
    marginTop: theme.spacing(1),
  },
  profileMenu: {
    minWidth: 200,
  },
  profileMenuLink: {
    fontSize: 16,
    fontFamily: 'Heebo, sans-serif',
    fontWeight: 400,
    textDecoration: 'none',
    color: Colors.BACKGROUND_BLACK,
    fontWeight: 600,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  menuArrow: {
    width: 12,
    marginLeft: 10,
  },
  menuRotate: {
    transform: 'rotate(-180deg)',
  },
  dialogStyle: {
    backgroundColor: Colors.GRAY,
  },
  dialogContentStyle: {
    color: Colors.BACKGROUND_BLACK,
  },
  logo_container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 15,
  },
}));
