import React from 'react';
import { BrowserRouter as RouterWrapper } from 'react-router-dom';
import Router from './routes';
import { SnackbarProvider } from 'notistack';

function App() {
  return (
    <RouterWrapper>
      <SnackbarProvider
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        maxSnack={3}
      >
        <Router />
      </SnackbarProvider>
    </RouterWrapper>
  );
}

export default App;
