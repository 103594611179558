import React from 'react';
import CommonButton from '../CommonButton';
import useStyles from './styles';

function SubscriptionCard(props) {
  const classes = useStyles();
  return (
    <div className={classes.subscription_card_container}>
      <div className={classes.subscription_cardItem_container}>
        {props.plan?.title && (
          <label
            htmlFor='title'
            style={{
              color: props.expiry
                ? props.plan.title === 'MONTHLY'
                  ? '#BD89FF'
                  : '#FF7078'
                : null,
            }}
            className={classes.card_title}
          >
            {props.plan.title}
          </label>
        )}
        {props.isUpdatePaymentInfoBtn ? (
          <div
            className={classes.plan_action_container}
            style={{ minHeight: 135 }}
          >
            <CommonButton
              onClick={props.onClick}
              text={'Update Payment Information'}
              className={classes.updateInfoBtn}
            />
          </div>
        ) : (
          <>
            {props.expiry ? (
              <p className={classes.plan_price} style={{ marginTop: 10 }}>
                Renews on {props.plan.expiryDate}
              </p>
            ) : (
              <>
                <p className={classes.plan_price}>{`$${props.plan.price}`}</p>
                {props.plan.title === 'YEARLY' && (
                  <p className={classes.plan_price}>{`(2 months free)`}</p>
                )}
              </>
            )}
          </>
        )}
      </div>
      {!props.expiry && !props.isUpdatePaymentInfoBtn ? (
        <CommonButton
          onClick={props.onClick}
          text={props.expiry && 'Cancel'}
          className={props.expiry && classes.cancelButton}
          style={{
            backgroundColor:
              props.plan.title === 'MONTHLY' ? '#BD89FF' : '#FF7078',
          }}
        />
      ) : null}
    </div>
  );
}
export default SubscriptionCard;
