import React, { useEffect, useState } from 'react';
import SubscriptionCard from '../../components/SubscriptionCard';
import {
  createSession,
  getSubscriptionDetail,
  planDetails,
  subscriptionCancel,
  updatePaymentInfo,
} from '../../utils/api';
import useStyles from './styles';
import { useSnackbar } from 'notistack';
import { getErrorMessage, logout } from '../../utils/appUtils';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import UpArrow from '../../assets/images/up-arrow.svg';
import clsx from 'clsx';
import * as Colors from '../../themes/colors';
import logo from '../../assets/images/logo.png';

function Subscription() {
  const { enqueueSnackbar } = useSnackbar();
  const [subscriptionLoading, setSubscriptionLoading] = useState(true);
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [planData, setPlanData] = useState([]);
  const [planLoading, setPlanLoading] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [cancelSubscriptionLoading, setCancelSubscriptionLoading] =
    useState(false);
  const [profileMenu, setProfileMenu] = useState(null);
  const [logoutModal, setLogoutModal] = useState(false);
  const [daysDifference, setDaysDifference] = useState(0);

  const onSubscriptionResponse = (data) => {
    setPlanData(data.data.data.reverse());
  };

  const onResponse = (data) => {
    const subscriptionData = data.data.data;
    setSubscriptionDetails(subscriptionData);
    if (!subscriptionData.lifetimeMember) {
      if (
        !subscriptionData.subscriptionStatus ||
        subscriptionData.subscriptionStatus === 'Expired' ||
        subscriptionData.subscriptionStatus === 'Cancel'
      ) {
        setPlanLoading(true);
        planDetails(
          (data) => onSubscriptionResponse(data),
          (err) => onError(err),
          setPlanLoading
        );
        // const date1 = new Date(subscriptionData.expiryDate);
        // const date2 = new Date();
        // const diffTime = Math.abs(date2 - date1);
        // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        // setDaysDifference(diffDays);
        // if (diffDays < 31 || new Date(subscriptionData.expiryDate) < new Date()) {
        //   setPlanLoading(true);
        //   planDetails(
        //     (data) => onSubscriptionResponse(data),
        //     (err) => onError(err),
        //     setPlanLoading
        //   );
        // }
      }
    }
  };

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  const handleGetSubscriptionDetails = () => {
    setSubscriptionLoading(true);
    getSubscriptionDetail(
      (data) => onResponse(data),
      (err) => onError(err),
      setSubscriptionLoading
    );
  };

  useEffect(() => {
    handleGetSubscriptionDetails();
  }, []);

  const classes = useStyles();

  const onSuccessCreateSession = (res) => {
    window.location.href = res.data.data.link;
  };

  const subscribe = (priceId) => {
    createSession(
      { priceId },
      (res) => onSuccessCreateSession(res),
      (err) => onError(err)
    );
  };

  const cancelSubscriptionOnClick = () => {
    setCancelDialog(true);
  };
  const handleClose = () => {
    if (cancelDialog) setCancelDialog(false);
    else setLogoutModal(false);
  };

  const onSuccessSubscriptionCancel = (res) => {
    setCancelDialog(false);
    enqueueSnackbar(res.data.data, {
      variant: 'success',
    });
    handleGetSubscriptionDetails();
  };
  const cancelSubscription = () => {
    setCancelSubscriptionLoading(true);
    subscriptionCancel(
      subscriptionDetails.subscriptionId,
      (res) => onSuccessSubscriptionCancel(res),
      (err) => onError(err),
      setCancelSubscriptionLoading
    );
  };

  const beforeDate = () => {
    let now = new Date(subscriptionDetails.expiryDate);
    const backdate = new Date(now.setDate(now.getDate() - 30));
    return backdate.toLocaleDateString();
  };

  const handleUpdatePaymentInfo = () => {
    updatePaymentInfo((data) => {
      window.open(data?.data);
    }, onError);
  };

  return (
    <div className={classes.containerStyle}>
      <div className={classes.subscription_container}>
        {subscriptionLoading || planLoading ? (
          <div className={classes.loaderContainer}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className={classes.headerContainer}>
              <div
                className={classes.headerActionContainer}
                onClick={(e) => setProfileMenu(e.currentTarget)}
              >
                <label className={classes.userName}>
                  {subscriptionDetails && subscriptionDetails.name}
                </label>
                <img
                  className={clsx(
                    classes.menuArrow,
                    !profileMenu && classes.menuRotate
                  )}
                  src={UpArrow}
                />
              </div>
              <Menu
                id='profile-menu'
                open={Boolean(profileMenu)}
                anchorEl={profileMenu}
                onClose={() => setProfileMenu(null)}
                className={classes.headerMenu}
                classes={{ paper: classes.profileMenu }}
                disableAutoFocusItem
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <MenuItem
                  onClick={() => {
                    setProfileMenu(null);
                    setLogoutModal(true);
                  }}
                >
                  <Typography className={classes.profileMenuLink}>
                    Logout
                  </Typography>
                </MenuItem>
              </Menu>
            </div>
            <div className={classes.plan_container}>
              <div className={classes.logo_container}>
                <img src={logo} height={'150px'} width={'150px'} />
              </div>
              {subscriptionDetails.lifetimeMember ? (
                <div className={classes.card_container}>
                  <div className={classes.lifetimeCard}>
                    You are already a lifetime member!
                  </div>
                </div>
              ) : (
                <>
                  {subscriptionDetails.subscriptionStatus &&
                  subscriptionDetails.subscriptionStatus === 'Active' ? (
                    <>
                      <p className={classes.plan_message}>
                        Hi {subscriptionDetails.name},
                        <br />
                        Your subscription details are listed below.
                      </p>
                      <div className={classes.card_container}>
                        <SubscriptionCard
                          plan={{
                            title: subscriptionDetails.subscription,
                            expiryDate: new Date(
                              subscriptionDetails.expiryDate
                            ).toLocaleDateString(),
                          }}
                          expiry
                          onClick={() => cancelSubscriptionOnClick()}
                        />
                        <SubscriptionCard
                          isUpdatePaymentInfoBtn
                          onClick={() => handleUpdatePaymentInfo()}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      {subscriptionDetails.subscriptionStatus &&
                      subscriptionDetails.subscriptionStatus === 'Cancel' ? (
                        <>
                          <p className={classes.plan_message}>
                            Hi {subscriptionDetails.name},
                            <br />
                            You do not currently have a membership to The
                            Physique Lab app. If you’d like to sign up and
                            access your data again, please choose a membership.
                          </p>
                          <div className={classes.card_container}>
                            {planData.map((plan, index) => (
                              <SubscriptionCard
                                key={`plan${index}`}
                                plan={plan}
                                onClick={() => subscribe(plan.priceId)}
                              />
                            ))}
                          </div>
                        </>
                      ) : (
                        <>
                          <p className={classes.plan_message}>
                            Hi {subscriptionDetails.name},
                            <br />
                            <br />
                            {!subscriptionDetails.subscriptionStatus ? (
                              <>
                                <span>
                                  We hope you enjoyed your 14-day free trial of
                                  Physique Lab. To continue using the app,
                                  please select either a yearly or monthly
                                  subscription plan. The yearly plan includes 2
                                  months free!
                                </span>
                                <br />
                                <br />
                                <span>
                                  We’ll routinely release fresh, innovative
                                  training programs, frequently update the app
                                  to introduce new features, and consistently
                                  streamline your fitness lifestyle to help you
                                  achieve faster results. You’ll never regret
                                  investing in a long-term commitment to
                                  understanding your body, working smarter both
                                  inside and outside of the gym, and improving
                                  your health!
                                </span>
                              </>
                            ) : (
                              `Your subscription has been expired. To
                                continue using the app, please select a yearly
                                or monthly subscription plan. The yearly plan
                                includes 2 months free. You'll never regret
                                investing in a long-term commitment to learn
                                your body and improve your health!`
                            )}
                          </p>
                          <div className={classes.card_container}>
                            {planData.map((plan, index) => (
                              <SubscriptionCard
                                key={`plan${index}`}
                                plan={plan}
                                onClick={() => subscribe(plan.priceId)}
                              />
                            ))}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>
      <Dialog
        open={cancelDialog || logoutModal}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent className={classes.dialogStyle}>
          <DialogContentText
            id='alert-dialog-description'
            className={classes.dialogContentStyle}
          >
            {`Are you sure you want to ${
              cancelDialog ? 'delete subscription?' : 'logout?'
            }`}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogStyle}>
          <Button onClick={handleClose} style={{ color: Colors.BLACK }}>
            No
          </Button>
          <Button
            autoFocus
            onClick={() => {
              if (cancelDialog) cancelSubscription();
              else logout();
            }}
            style={{ color: Colors.BLACK }}
          >
            {cancelSubscriptionLoading ? <CircularProgress size={20} /> : 'Yes'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default Subscription;
